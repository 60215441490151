/*--------------------------------------------------------------------------------------*/
/*General-Css-Here
/*--------------------------------------------------------------------------------------*/
*{margin:0;padding:0;box-sizing:border-box}
body{margin:0;padding:0;box-sizing:border-box;font-family: 'Nunito Sans', sans-serif;}
a{-webkit-transition:.5s ease-out;-moz-transition:.5s ease-out;-o-transition:.5s ease-out;transition:.5s ease-out}
ul{margin:0;padding:0;list-style:none}
figure,h1,h2,h3,h4,h5,h6{margin:0;padding:0}
img{max-width:100%}
footer,header,section{display:block}
footer:after,footer:before,header:after,header:before,section:after,section:before{display:table;clear:both;content:""}

a:focus{border:0;outline:0}
button:focus{border:0;outline:0}
select{appearance:none}
p,span{margin:0;padding:0}
a:hover{text-decoration:none}
html{scroll-padding-top: 5rem;}

/* Gradient Scroll Bar */

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    border-radius: 100vh;
    background:rgb(214, 189, 182);
}
  
/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(230, 82, 82);
}
/*--------------------------------------------------------------------------------------*/
/*font-family
/*--------------------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800;6..12,900&display=swap');

/*------------------------------------------------------------------------------------*/
/*common-css
/*--------------------------------------------------------------------------------------*/
.bg-grey{background:rgb(29 22 22 / 2%)}
.bg-black{background:#1d1616}
.divider{border-color:rgb(0 0 0 / 14%);opacity:1}
.section-head h2{font-size:36px;color:#1d1616;font-weight:700}
.section-head p{font-size:14px;color:#74788a;line-height:1.6}
.section-padding{padding:100px 0 100px}
.cpt{padding-top:81.35px}

.form-control{height:44px;border:1px solid #d8e2e9;font-size:15px;color:#000;border-radius:4px;padding:5px 16px}
.form-control:focus,.form-select:focus{outline:0;box-shadow:none;border-color:#7bc255}
.form-control::-webkit-input-placeholder{color:rgb(0 0 0 / 45%)}
.form-control:-ms-input-placeholder{color:rgb(0 0 0 / 45%)}
.form-control::placeholder{color:rgb(0 0 0 / 45%)}
.form-label{font-size:15px;color:#000;font-weight:600;margin-bottom:5px}

textarea.form-control.note-min{height:124px;resize:none}
input.form-control{background:#f2f4f4;border-radius:6px;border:0;font-size:14px;color:#868a91;    font-weight: 600;}

.otp-form input.form-control{margin:0 5px;text-align:center;font-size:20px;color:#000}
.otp-form input.form-control::-ms-input-placeholder{color:#000}
.otp-form input.form-control::placeholder{color:#000}

input.form-control:focus{background:#f2f4f4}

.form-bottom-text span{font-size:14px}

.nav-tabs .nav-item{width:50%}
.nav-tabs .nav-link{font-size:14px;font-weight:600;border:0;color:#000;width:100%;margin:0;padding:10px 10px}
.nav-tabs .nav-link.active{color:#fa5856;font-weight:700;border-bottom:2px solid #fa5856;margin-bottom:-1px}

.btn:focus{box-shadow:none}
.btn{font-size:15px;border-radius:6px;border:0;height:44px;display:inline-flex;align-items:center;justify-content:center;padding:10px 15px;line-height:24px;font-weight:700}
.btn-primary{background:#faa653;color:#fff}
.btn-primary:hover{background:#faa653;opacity:.8}
.btn.min-w{width:217px}
.btn-secondry{background:#fa5856;color:#fff}
.btn-secondry:hover{background:#fa5856;opacity:.8;color:#fff}

.form-link{font-size:15px;font-weight:800;color:#fa5856;text-decoration:none}
.form-bottom{padding:15px 10px 20px;text-align:center;border-top:1px solid #dadddf;margin-left:-35px;margin-right:-35px}
.form-body{min-height:calc(100vh - 555px);padding-bottom:80px}
.form-group-double .form-group:first-child{margin-right:5px}
.form-group-double .form-group:last-child{margin-left:5px}

select.form-select{background-color:#f2f4f4;border-radius:6px;border:0;font-size:14px;color:#868a91;height:44px;background:#f2f4f4 url(../images/drop-icon.png);background-repeat:no-repeat;background-position:center right 10px}
/*--------------------------------------------------------------------------------------*/
/*home
/*--------------------------------------------------------------------------------------*/

header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    top: 0;
    padding: 10px 0;
    border-bottom: 1px solid #DADDDF;
        background: #fff;
}
.topHeader .navbar-nav li a:hover{color:#fa5856!important}
.navbar-brand{padding:0;margin-right:25px}
.navbar.navbar-expand-lg{padding:0}
.navbar-inner-box{width:100%}
.navbar-nav>li>a {
    font-size: 14px;
    color: #000000!important;
    padding: 0 15px!important;
    line-height: normal;
    text-decoration: none;
    display: block;
    font-weight: 600;
}
.navbar-nav .nav-item .nav-link.active{color:#fa5856!important}
.navbar-toggler:focus{box-shadow:none}
.footer-link li a:hover {
    color: #fa5856;
}
.Social-link li a:hover {
    background: #fa5856;
}


.top-head{background:#faa653;text-align:center;padding:15px 0}
.form-inner.form-space{position:relative}
.common-form{width:433px;margin:auto;padding:40px 35px;border-radius:10px;background-color:#fff}
.common-form:after{background:url(../images/login-background.png);content:"";position:absolute;background-size:contain;top:0;left:0;right:0;bottom:0;background-repeat:no-repeat;z-index:-1;width:652px;margin:auto;display:flex;background-position:-19px 107px}
.form-head h4{font-size:24px;font-weight:700;padding-bottom:3px}
.form-head p{font-size:14px;color:#868a91}
.form-space{padding:120px 0 100px}
.select-list-option li{background:rgb(250 88 86 / 11%);display:inline-flex;align-items:center;font-size:14px;font-weight:600;padding:10px 10px;border-radius:4px;border:1px solid #fa5856;height:38px;justify-content:space-between;margin-right:5px;margin-bottom:5px}
.option-close i{font-size:22px;color:#000;font-weight:400}
.option-close{text-decoration:none;padding-left:10px}
.form-check-label{font-size:15px;color:#000;font-weight:600}
.form-check-label .form-link{font-weight:600}
.form-check-input:checked{background-color:#fa5856;border-color:#fa5856}
.form-check-input:focus{outline:0;box-shadow:none;border:var(--bs-border-width) solid var(--bs-border-color)}
.capcha-box-right{flex:0 0 46px;margin-left:10px}
.capcha-box-left{flex:1}
.form-divider{border-color:#dadddf;opacity:1}


.form-modal .model-head{background:linear-gradient(to bottom,#faa653 0,#fa5856 100%);text-align:center;padding-top:40px}
.form-modal .modal-body{padding:0}
.form-modal .modal-content{border-radius:10px;overflow:hidden;border:0}
.form-modal .model-head figure{width:94px;height:94px;background:#fff;display:flex;align-items:center;justify-content:center;border-radius:50%;margin:auto;box-shadow:0 0 6px rgb(102 102 102 / 10%);position:relative;top:40px;margin-bottom:40px}
.form-modal .modal-body figcaption{text-align:center}
.form-modal .modal-body figcaption{text-align:center;padding:25px 60px 40px}
.form-modal .modal-body figcaption h4{font-size:18px;color:#000;font-weight:700;padding-bottom:5px}
.form-modal .modal-body figcaption p{font-size:14px;color:#868a91}
.form-modal .modal-dialog{margin-top:130px}


.user-profile-drop {
    display: inline-flex;
    align-items: center;
    border: 0;
    padding: 0 10px;
    border-radius: 33px;
    min-width: 89px;
    font-size: 14px;
    color: #868a91;
    height: 44px;
    background: #f2f4f4 url(../images/drop-icon.png);
    background-repeat: no-repeat;
    background-position: center right 10px;
}
.user-profile-drop span {
    width: 36px;
    height: 36px;
    background: #FA5856;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    margin-right: 10px;
}

.user-profile-drop.dropdown-toggle::after{ display:none }


.section-space {
    padding: 100px 0;
}

.page-title h1 {
    font-size: 30px;
    font-weight: 700;
    color: #000;
}

.dashboard-info {
    border-radius: 10px;
    background: #ddd;
    padding: 35px 30px;
    position: relative;
}

.dashboard-info-left h3 {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    padding-bottom: 5px;
}

.dashboard-info-left p{ font-size:16px; font-weight: 600; color: #ffff}

.dashboard-info-right span {
    width: 62px;
    height: 62px;
    background: #Fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.dashboard-info-right span i{ font-size:28px }

.dashboard-info:after {
    width: 90%;
    height: 30px;
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    background: red;
}
.dashboard-info:after{width: 92%; height: 30px; position: absolute; left: 0; right: 0; content: ""; background: #eee; margin: auto; bottom: -8px; z-index: -1; border-radius: 10px;}
.dashboard-info.yallow-dash-info{ background:#FAA653 }
.dashboard-info.yallow-dash-info:after{ background:#FFD6A9 }
.dashboard-info.yallow-dash-info .dashboard-info-right span i{ color:#FAA653 }
.dashboard-info.green-dash-info{ background:#025D61 }
.dashboard-info.green-dash-info:after{ background:#0A8388 }
.dashboard-info.green-dash-info .dashboard-info-right span i{ color:#025D61 }
.pink-dash-info{ background:#FA5856 }
.dashboard-info.pink-dash-info:after{ background:#FF9192 }
.dashboard-info.pink-dash-info .dashboard-info-right span i{ color:#FA5856 }
.blue-dash-info{ background:#00B3BD }
.dashboard-info.blue-dash-info:after{ background:#0BC9D3 }
.dashboard-info.blue-dash-info .dashboard-info-right span i{ color:#00B3BD }
.section-title h2{font-size:24px;color:#000;font-weight:700;padding-bottom:8px}
.section-title p{font-size:14px;color:#868a91}
.box-shadow{box-shadow:0 1px 10px rgb(65 65 65 / 12%);border-radius:10px;padding:30px 30px}
.footer-content h3{font-size:18px;color:#000;font-weight:700;padding-bottom:15px}
.footer-link li a{font-size:15px;color:#000;text-decoration:none;font-weight:600;padding-bottom:8px;display:block}
.footer-box{background:#f2f4f4;padding-top:70px;padding-bottom:50px;position:relative}
.Social-link li a{background:#000;width:44px;height:44px;display:flex;align-items:center;justify-content:center;border-radius:50%;color:#fff;text-decoration:none;font-size:21px}
.Social-link li{margin-right:15px}
.footer-box:after{width:222px;height:222px;background:#00747f;position:absolute;left:-170px;top:50px;content:"";border-radius:50%;margin:auto}
.footer-box:before{width:222px;height:222px;background:#fa5856;position:absolute;right:-170px;top:50px;content:"";border-radius:50%;margin:auto}
footer{overflow:hidden}
.footer-top-left p{font-size:14px;line-height:24px;font-weight:500}
.coupan-box figure{width:135px;height:134px;border-radius:8px;overflow:hidden}
.coupan-box figure img{width: 100%; height: 100%; object-fit: cover;}
.coupan-box figcaption{flex:1;margin-left:20px}
.coupan-box figcaption h4{font-size:16px;color:#000;font-weight:600;padding-bottom:8px}
.coupan-box figcaption .date-text{font-size:15px;font-weight:600;color:#fa5856;display:flex;align-items:center;margin-bottom:5px;    line-height: normal;}
.coupan-box figcaption .date-text i{margin-right:5px;font-size:20px;font-weight:400}
.discount-text{font-size:14px;color:#000;margin-bottom:5px;display:block}
.coupan-box figcaption p{font-size:14px;color:#868a91;line-height:21px}
.coupan-sec-list{background:#f2f4f4;padding:10px 10px;border-radius:30px;width:140px;height:45px;align-items:center}
.coupan-sec-list.d-flex li i{font-size:22px}
.coupan-sec-list li{width:50%;text-align:center;border-right:1px solid #ddd}
.col-md-6.coupan-box{margin-bottom:20px}
.coupan-sec-list li:last-child{border-right:0;padding-right:0}
.coupan-sec-list li a{display:flex;align-items:center;justify-content:center;line-height:normal;text-decoration: none;color: inherit;}
.btn.btn-secondry i{font-size:24px;font-weight:300}
.pagination-nav{background:#f2f4f4;display:inline-flex;border-radius:10px;padding:7px 10px}
.pagination-nav .page-item{border:0}
.pagination-nav .page-item{background:0 0;font-size:14px;color:#868a91;display:flex;align-items:center;line-height:normal;width:25px;height:25px;padding:0;justify-content:space-evenly;font-weight:600}
.pagination-nav .page-item.active{background:#fa5856;border-radius:4px;font-weight:600;color:#fff}
.avatar-upload{position:relative;width:90px;margin:auto}
.avatar-upload .avatar-edit input{display:none}
.avatar-upload .avatar-preview{width:90px;height:90px;position:relative;border-radius:50%;margin-bottom:20px}
.avatar-upload .avatar-preview .avatar-preview-img{width:100%;height:100%;border-radius:50%;background-size:cover;background-repeat:no-repeat;background-position:center}
.avatar-upload .avatar-edit .img-upload-icon{display:inline-block;width:38px;height:38px;margin-bottom:0;border-radius:100%;background:#faa653;cursor:pointer;font-weight:400;transition:all .2s ease-in-out;position:absolute;bottom:-2px;top:auto;right:-8px;z-index:99;display:grid;align-items:center;justify-content:center;color:#fff}
.avatar-upload label.img-upload-icon i{font-size:24px;font-weight:300}
textarea.form-control.textarea-box{height:142px;background:#f2f4f4;border:0;resize:none;border-radius:4px;padding:10px 16px;    font-size: 14px;}
.breadcrumb .breadcrumb-item a{font-size:14px;color:#faa653;font-weight:600;text-decoration:none}
.breadcrumb .breadcrumb-item.active{color:#868a91;font-size:14px}
.breadcrumb-item+.breadcrumb-item::before{color:#faa653}
.file-upload-wrapper{position:relative;width:100%;height:45px}
.file-upload-wrapper:after{content:attr(data-text);position:absolute;top:0;left:0;padding:10px 15px;display:block;width:100%;pointer-events:none;height:45px;line-height:26px;font-weight:400;background:#f2f4f4;border-radius:6px;border:0;font-size:14px;color:#868a91;z-index:9}
.file-upload-wrapper:hover:before{background:#3d8c63}
.file-upload-wrapper input{opacity:0;position:absolute;top:0;right:0;bottom:0;left:0;z-index:22;height:45px;margin:0;padding:0;display:block;cursor:pointer;width:100%;padding-right:50px}
.inner-banner-main{background:#f5fdf7;overflow:hidden;position:relative;margin-bottom:60px}
.btn-upload{position:absolute;right:15px;top:0;bottom:0;z-index:11;border:0}
.btn-upload i{font-size:22px;color:#b2b6bb;font-weight:300}
.uploaded-img-list{display:flex;margin-top:20px;margin-left:-10px;margin-right:-10px;flex-wrap: wrap;}
.uploaded-img-list li{width:20%;padding:0 10px 10px}
.uploaded-img-list li figure img{width:100%;height:101px;border-radius:8px}
.uploaded-img-list li{position:relative}
.close-img{width:26px;height:26px;position:absolute;right:18px;top:10px;background:rgb(0 0 0 / 43%);z-index:9;display:flex;align-items:center;justify-content:center;border-radius:50%;text-decoration:none}
.close-img i{color:#fff;line-height:normal}
.btn.max-w{width:373px}
.switch{position:relative;display:inline-block;width:54px;height:25px}
.switch input{opacity:0;width:0;height:0}
.slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;-webkit-transition:.4s;transition:.4s}
.slider:before{position:absolute;content:"";height:18px;width:18px;left:4px;bottom:3px;background-color:#fff;-webkit-transition:.4s;transition:.4s;box-shadow:0 4px 8px rgb(44 39 56 / 20%)}
.switch input:checked+.slider{background-color:#fa5856}
.switch input:focus+.slider{box-shadow:0 0 1px #2196f3}
.switch input:checked+.slider:before{-webkit-transform:translateX(27px);-ms-transform:translateX(27px);transform:translateX(27px)}
.slider.round{border-radius:34px}
.slider.round:before{border-radius:50%}
.action-info li{margin-left:15px}
.action-info li a{width:44px;height:44px;display:flex;align-items:center;justify-content:center;border-radius:50%}
.action-info li button{width:44px;height:44px;display:flex;align-items:center;justify-content:center;border-radius:50%;background: transparent;border: none;font-size: 20px;}
.action-info li a{font-size:20px;text-decoration:none}
.action-info li.edit-action{background:#ffc3c2;border-radius:50%}
.action-info li.edit-action i{color:#000}
.action-info li.delete-action{background:#faa653;border-radius:50%}
.action-info li.delete-action i{color:#fff}
.coupan-box-detail figure{width:200px;height:165px;margin-right:26px;overflow:hidden;border-radius:10px}
.coupan-box-detail figcaption h4{font-size:24px;color:#000;font-weight:600;padding-bottom:10px}
.date-box{color:#fa5856;display:flex;align-items:center;font-size:16px}
.date-box i{padding-right:4px}
.coupon-sec-detail-content h4{font-size:18px;color:#000;font-weight:700;padding-bottom:5px}
.coupon-sec-detail-content span{font-size:15px;color:#000}
.coupon-sec-des p{color:#868a91}
.gallery-img-list li{width:169px;height:123px;border-radius:10px;overflow:hidden;margin-right:15px;margin-bottom:15px}
.gallery-img-list li figure img{width: 100%; height: 100%; object-fit: cover;}
.gallery-img-list{flex-wrap:wrap}
.coupan-box-detail figcaption{flex:1}
.common-modal button.btn-close{position:absolute;right:15px;top:18px;font-size:14px}
.common-modal .modal-title{font-size:18px;font-weight:700}
.common-modal .modal-content{border:0;border-radius:10px}
.common-modal .modal-content{border:0;border-radius:10px;padding:25px 25px 30px}
.common-modal .single-content-modal p{color:#868a91;font-size:14px}
.common-modal .modal-dialog{margin-top:185px}
.input-cal-icon{position:absolute;right:10px;top:0;bottom:0;display:flex;align-items:center;font-size:20px;color:#b2b6bb}
.date-box{position:relative}
.date-box.date.datepicker{padding:0}
.datepicker td,.datepicker th{width:35px!important;height:30px!important;font-size:14px!important}
.table-condensed{width:100%}
.datepicker table tr td span{height:40px!important;line-height:40px!important}
.Popular-coupan-box figure{width:73px;height:65px}
.Popular-coupan-box figure img{height:100%;width:100%}
.arrow-box a{width:50px;height:38px;background:#f2f4f4;display:flex;align-items:center;justify-content:center;border-radius:10px;font-size:22px}
.arrow-box a i{color:#b2b6bb}
.coupan-sec-list li.active i{color:#fa5856}
.coupon-detail-info li{display:flex;align-items:center;justify-content:space-between;padding-bottom:12px;border-bottom:1px solid #dadddf;margin-bottom:12px}
.coupon-detail-info li b{font-size:15px;color:#000;font-weight:600}
.coupon-detail-info li p{font-size:15px;color:#868a91;font-weight:600}
.coustom-check label.form-check-label{width:100%}
.form-check.coustom-check{padding-left:0;position:relative}
.coustom-check .form-check-input:checked~.form-check-label{border:1px solid red;border-radius:10px}
.coustom-check .form-check-input[type=radio]{border-radius:50%;position:absolute;top:0;bottom:0;margin:auto;left:10px}
.coustom-check .coupan-box.Popular-coupan-box{padding-left:42px!important}
.model-sm-title{font-size:16px;color:#868a91;padding-bottom:15px}
.coustom-check-list li{margin-bottom:12px}
.min-sm-w{width:177px}

.business-configuration-box{background:#21252b;padding:20px 30px;border-radius:10px}
.business-configuration-left figure{width:90px;height:90px;overflow:hidden;border-radius:50%;margin-right:15px}
.business-configuration-left figcaption h4{color:#fff;font-size:22px;font-weight:700;padding-bottom:5px}
.business-configuration-left figcaption span{font-size:16px;color:#fff}
.bus-conf-content p,.bus-conf-content span{font-size:14px;color:#868a91;padding-bottom:0;margin-bottom:10px}
.bus-conf-content span{display:inline-block}
.bus-conf-content p b,.bus-conf-content span b{color:#000}
.bus-conf-content h4{font-size:18px;color:#000;font-weight:700;padding-bottom:10px}
.bus-conf-content span{padding-right:30px}
.business-configuration-left{flex:1;margin-right:20px}

.sub-title-page.mb-3{font-size:18px;color:#000;font-weight:700}
.sub-label{font-size:14px;color:#868a91}
.my-account-left{width:250px;height:100%}
.my-account-right{flex:1}
.my-account-left .nav-tabs .nav-link{text-align:left;font-size:16px;background:0 0}
.my-account-left{background:#f2f4f4;border-radius:10px;padding:20px 21px}
.my-account-left .nav-tabs .nav-link.active{border-bottom:0;margin-bottom:0}
.my-account-left .nav-tabs{border:0}
.my-account-left .nav-tabs .nav-link i{font-size:22px;vertical-align:sub;font-weight:300}
.my-profile-list li{padding-bottom:15px;display:flex;align-items:center;justify-content:space-between;flex-wrap:wrap}
.my-profile-list li span{font-size:15px;color:#868a91}
.my-profile-list li{border-bottom:1px solid #dadddf;padding-bottom:13px;margin-bottom:13px}
.my-profile-list li p{color:#868a91;font-size:14px}
.my-profile-list li:last-child{border-bottom:0;padding-bottom:0;margin-bottom:0}
.edit-action.ping-bg-edit{background:#fa5856}
.edit-action.ping-bg-edit i{color:#fff}
.Notifications-list li{display:flex;align-items:center;justify-content:space-between;padding-bottom:6px;margin-bottom:6px}
.Notifications-list li b{font-weight:500;font-size:15px}
.switch-toggle-xs .switch{width:38px;height:18px}
.switch-toggle-xs .slider:before{height:13px;width:13px}
.switch-toggle-xs .switch input:checked+.slider:before{-webkit-transform:translateX(18px);-ms-transform:translateX(18px);transform:translateX(18px)}
.setting-info-head h4{font-size:15px;font-weight:700}
.nav-R{display: flex;}
.nav-R .dropdown-menu{border:0;box-shadow:0 1px 10px rgb(65 65 65 / 12%);border-radius:10px;min-width:166px}
.nav-R .dropdown-menu .dropdown-item{font-size:15px;color:#000;font-weight:600;display:flex;align-items:center}
.nav-R .dropdown-menu .dropdown-item i{margin-right:8px;font-size:16px;font-weight:600}
.business-configuration-left figcaption{flex:1}
.business-configuration-left figure{width:40px;height:40px}
.business-configuration-left{align-items:flex-start!important}
.breadcrumb{margin:0;display:flex;align-items:center;flex-wrap:wrap}
.notification-blog{position:absolute;top:44px;right: 0px !important;background:#fff;border-radius:10px;box-shadow:0 1px 10px rgb(65 65 65 / 12%);min-width:356px !important;}
.notifiy-btn{height:44px;width:44px;display:flex;align-items:center;justify-content:center;background:#fa5856;border-radius:50%;color:#fff;font-size:24px;position:relative;margin-right:20px}
.notification-list li h4{font-size:15px;color:#000;font-weight:600;padding-bottom:7px}
.notification-list li p{font-size:14px;color:#868a91;line-height:21px}
.notification-list li{padding-bottom:15px;padding-top:15px;border-bottom:1px solid #dadddf;padding-left:15px;padding-right:25px}
.notification-item:hover{background:rgb(250 88 86 / 6%);border-bottom:0!important}
.notification-list li:last-child{border-bottom:0}
.notification-list{max-height:402px;overflow:auto}
.notification-list:not(:first-child){background-color: #900;}



.calender-table-box{border:solid 1px #DADDDF; overflow: auto; max-height: 600px;}
.calender-table-box table{border-top: none; border-bottom: none; margin: 0;}
.calender-table-box table tr td{padding:8px 10px; font-weight: 600; border-bottom: solid 1px #DADDDF; border-right: solid 1px #DADDDF; color: #868A91; font-size: 15px; vertical-align: top;}
.calender-table-box table tr:last-child td{border-bottom: none;}

.event-value span{font-size: 14px; color:#000; position: relative; padding-left: 8px; margin-right: 25px;}
.event-value span:before{content: ""; width: 8px; height: 8px; background: #000; border-radius: 50px; position: absolute; left:-8px; top:5px;}
.table-left-box{width: 120px; text-align: center;}
.event-value .orange{color: #FAA653;}
.event-value span.orange:before{background: #FAA653;}

.event-value .green{color: #30CF66;}
.event-value span.green:before{background: #30CF66;}


.event-value .red{color: #FA5856;}
.event-value span.red:before{background: #FA5856;}

.event-value .dark-green{color: #247C42;}
.event-value span.dark-green:before{background: #247C42;}
.calendar-tab-head{display: flex; align-items: center; justify-content: space-between; margin-bottom: 30px;}
.calendar-tab-head h2{font-size: 24px; color:#000; font-weight: bold;}
.tab-head ul{display: flex; align-items: center; background: #F2F4F4; border-radius: 50px; padding: 10px 0px;}
.tab-head ul li{border-right:solid 1px #DADDDF;}
.tab-head ul li:last-child{border-right: none;}
.tab-head ul li a{font-size: 15px; color:#868A91; font-weight: 500; text-decoration: none; height: 30px; line-height: 30px; padding: 0px 20px; display: block;}
.tab-head ul li.active a{color: #FA5856;}
.weekly-table-box .calender-table-box table tr td {min-width: 150px;}
.weekly-table-box .calender-table-box table tr td.table-left-box{min-width: 120px;}

.weekly-table-box .monthly-table-box table tr td {min-width: 150px;}

















