@media(max-width:1199px){
    .footer-box:after{width:200px;height:200px}
    .footer-top-left{padding-left:30px;padding-right:20px}
    .add-new-coupon-box.m-auto{width:90%}
    .common-form{width:47%}
    .form-space{padding:60px 0 60px}
    .coupan-box.Popular-coupan-box{width:100%;margin-bottom:20px}
    
    .col-md-8.publish-coupon-box.m-auto{width: 100%;}


    }
    @media(max-width:991px){
    .dashboard-info-main .col-md-3{width:50%;margin-bottom:30px}
    .nav-L.d-flex{flex:1}
    ul.Social-link{flex-wrap:wrap}
    .navbar-collapse{background-color:#000;position:fixed;top:0;left:-350px;z-index:9;width:300px;height:100vh;transition:.3s;padding-top:40px}
    .navbar-collapse.show{transition:.5s;left:0;margin-left:0}
    span.navbar-toggler-icon{display:flex;align-items:center;justify-content:center;height:35px;width:38px;border-radius:4px;background:0 0}
    body .navbar-nav>li>a{font-size:14px;color:#fff!important;padding:10px 15px!important;line-height:normal;text-decoration:none;display:block;font-weight:600}
    button.navbar-toggler{width:40px;height:36px;display:flex;align-items:center;justify-content:center;background:#21252b;color:#fff;font-size:24px;border-radius:4px;bottom:0;margin:auto;position:absolute;right:0;z-index:9;padding:0;line-height:normal;top:0}
    .nav-R{padding-right:58px}
    .section-space{padding:50px 0}
    .page-title h1{font-size:22px}
    .col-md-8.add-new-coupon-box.m-auto{width:100%;}
    .common-form{width:65%}
    .my-account-left{padding:14px 12px}
    .col-md-6.coupan-box{margin-bottom:20px;width:100%}
    }
    @media(max-width:767px){
    .col-md-4.footer-content{margin-bottom:25px}
    .col-md-3.footer-content{padding-left:48px}
    .uploaded-img-list li figure img{height:53px;object-fit:cover}
    .coupan-box-detail figcaption h4{font-size:22px}
    .gallery-img-list li{width:157px;height:114px}
    .coupon-sec-des p{font-size:14px}
    .common-form:after{width:100%}
    .common-form{width:80%}
    .my-account-main.d-flex{flex-wrap:wrap}
    .my-account-right{flex:1;margin-left:0!important;margin-top:25px}
    .my-account-left{width:100%;height:auto;display:flex}
    .my-account-left .nav-tabs{width:100%}
    .my-account-left .nav-tabs .nav-link{text-align:center;font-size:16px;background:0 0;width:50%; display: flex; align-items: center; justify-content: center;}
    .my-account-left .nav-tabs .nav-link i{margin-right: 8px;}
    .my-account-left{padding:0}
    .coupan-sec-top-head{flex-wrap:wrap}
    .coupan-sec-top-head-right{width:100%;margin-top:20px;justify-content:flex-end}
    .dashboard-info{margin-bottom:25px}
    .support-info-main.mt-5 figure{text-align:center;margin-bottom:40px}
    }
    @media(max-width:575px){
    .dashboard-info-main .col-md-3{width:100%;margin-bottom:30px}
    .page-title h1{font-size:22px}
    .dashboard-info{padding:25px 30px}
    .business-configuration-box{flex-wrap:wrap}
    .business-configuration-right{width:100%;margin-top:10px}
    .footer-box:before{width:200px;height:200px}
    .coupan-box-detail figure{width:150px;height:123px}
    .nav-tabs .nav-link{padding:10px 7px}
    .common-form{width:80%;padding:30px 20px}
    .form-label{font-size:14px}
    .section-space{padding:30px 0}
    .my-account-info-main{margin-top:20px!important}
    .nav-R .dropdown-menu{left:auto;right:0}
    .nav-R .dropdown-menu .dropdown-item{font-size:13px}
    .btn{font-size:14px}
    .coupan-box figcaption h4{font-size:14px}
    .coupan-box figcaption .date-text{font-size:14px}
    .section-title h2{font-size:22px}
    .form-head h4{font-size:20px}
    .otp-form input.form-control{font-size:16px}
    .top-head{padding:10px 0}
    .top-head figure img{width:65px}
    .publish-coupon-info-main.mt-5{margin-top:35px!important}
    .form-bottom{margin-left:-20px;margin-right:-20px}
    .notification-blog{min-width: 270px;}
     
    .delete-box-style .modal-foot{display: flex; flex-wrap: wrap;}
    .delete-box-style .modal-foot .btn{width: 100%; flex:auto; margin: 5px 0px !important;}

    }
    @media(max-width:480px){
    .dashboard-info-left h3{font-size:22px}
    .footer-box:before{width:200px;height:200px}
    .footer-top-left img{width:100px}
    .footer-box{padding-top:40px;padding-bottom:30px}
    .footer-link li a{font-size:14px}
    .Social-link li a{width:33px;height:33px}
    .Social-link li{margin-right:10px}
    .btn.max-w{width:100%}
    .add-new-coupon-info-main.mt-5{margin-top:20px!important}
    .uploaded-img-list li{width:33%;padding:0 5px 10px}
    .min-sm-w{width:135px;font-size:13px;height:45px}
    .business-configuration-left figcaption h4{font-size:18px}
    .business-configuration-left figcaption span{font-size:14px}
    .business-configuration-box{padding:15px 20px}
    .section-space{padding:30px 0}
    .page-title h1{font-size:20px}
    .business-configuration-left figcaption h4{font-size:16px;padding-bottom:3px}
    .coupon-sec-detail-top-head{flex-wrap:wrap}
    .coupan-sec-top-head-right{margin-top:15px}
    .coupan-box-detail{flex-wrap:wrap}
    .coupan-box-detail figcaption{flex:initial;margin-top:20px}
    .coupan-box-detail figure{width:100%;height:auto;margin-right:0}
    .coupan-box-detail figure img{width:100% !important;}
    .gallery-img-list li{width:45%;height:109px}
    ol.breadcrumb{font-size:20px}
    .box-shadow{padding:25px 20px}
    .form-head h4{font-size:22px}
    .line-tabs ul#myTab{flex-wrap:nowrap;white-space:nowrap;overflow-y:hidden;overflow-x:auto}
    .nav-tabs .nav-item{width:auto; flex: 1;}
    .nav-tabs .nav-link{padding:10px 10px; font-size: 13px;}
    .common-form{width:100%; padding:30px 20px}
    .form-space{padding:45px 0 60px}
    .form-bottom span{font-size:14px}
    .form-link{font-size:14px}
    .otp-form input.form-control{font-size:16px}
    .my-account-left .nav-tabs{width:100%;flex-wrap:nowrap;white-space:nowrap;overflow:auto}
    .my-account-left .nav-tabs .nav-link{width:auto}
    .my-account-left .nav-tabs .nav-link{font-size:14px}
    .section-title h2{font-size:20px;padding-bottom:0}
    .navbar-brand.desktop-view-logo img{width:75px}
    .user-profile-drop span{width:30px;height:30px}
    .user-profile-drop{height:38px;font-size:14px;min-width:82px}
    .user-profile-drop span{font-size:12px}
    .user-profile-drop{padding:0 5px}
    .btn{font-size:13px}
    .col-md-6.coupan-box .box-shadow{flex-wrap:wrap}
    .coupan-box figcaption{flex:inherit;margin-left:0;margin-top:20px;width:100%}
    .coupan-box.Popular-coupan-box .box-shadow{flex-wrap:wrap}
    .dashboard-info{padding:20px 25px}
    .coupan-sec-list{width:112px}
    .form-body .form-group{margin-bottom:15px!important;width:100%}
    .form-group-double{flex-wrap:wrap}
    .form-head h4{font-size:20px}
    .support-info-main figure img{width:265px}
    .support-info-main .section-title.mb-4{margin-bottom:15px!important}
    .navbar-collapse{width:250px}
    .notifiy-btn{height:38px;width:38px;margin-right:6px}
    .notifiy-btn img{width:18px}
    .notification-blog{right:-120px}
    .login-logo-withoutnav img{width: 120px; margin-bottom: 10px;}
    span.form-error.form-error-special{width: 100%;}
    body .fc .fc-toolbar-title{font-size: 15px; font-weight: bold;}
    .calendar-tab-head h2{font-size: 18px;}
    .tab-head ul li a{padding: 0px 10px;}
}