@media only screen and (min-width: 150px) and (max-width:407px) {

    .responsive-btn button.btn.btn-secondry.max-w,
    .responsive-btn a.btn.btn-secondary.max-w.login-support-back {
        width: 100%;
        margin-bottom: 2%;
        margin-left: 0%;
    }

    .responsive-btn a.btn.btn-secondary.max-w.login-support-back {
        width: 100%;
        margin-left: 0%;
    }
    .mobilepicker-custom div.MuiInputBase-root{
        width: 100% !important;
      }
      .reject-custom-css {
        margin-top: 9px;
        width: 100% !important;
    }
}

@media only screen and (min-width: 401px) and (max-width:1399px) {

    .responsive-btn button.btn.btn-secondry.max-w,
    .responsive-btn a.btn.btn-secondary.max-w.login-support-back {
        width: 44%;
    }
    .mobilepicker-custom div.MuiInputBase-root{
        width: 100% !important;
      }

}


@media only screen and (min-width: 770px) and (max-width:1204px) {

    .mobilepicker-custom div.MuiInputBase-root{
        width: 100% !important;
      }

      a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-timegrid-event.fc-v-event.purchased-event, a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event.purchased-event, a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event.purchased-event {
        height: 43px;
        margin-top: 4px;
    }

.coupon-sec-detail-content.mb-4.coupon-sec-des {
    margin-right: 20%;
}
.lastenddate {
    margin-right: 24.5% !important;
}
}

@media only screen and (min-width: 481px) and (max-width:767px) {
    .coupan-sec-top-head-right {
        width: 0 !important;
    }

    .col-md-6.mb-3.add-coupan-service-duration {
        width: 100%;
    }

    .backshadow {
        top: -25px;
    }
    .mobilepicker-custom div.MuiInputBase-root{
        width: 100% !important;
      }
      .coupon-sec-detail-content.mb-4.coupon-sec-des {
        margin-right: 15%;
    }
    .lastenddate {
        margin-right: 27.5% !important;
    }
        
      
}

@media only screen and (min-width: 250px) and (max-width:481px) {
    .mobilepicker-custom div.MuiInputBase-root{
        width: 100% !important;
      }
    .coupan-sec-top-head-right {
        width: 100% !important;
    }

    .col-md-6.mb-3.add-coupan-service-duration {
        width: 100%;
    }

    .coupan-box figure {
        border-radius: 8px;
        margin: auto;
        text-align: center;
        overflow: hidden;
    }

    .backshadow {
        top: -25px;
    }

    .gallery-img-list li {
        height: auto;
        width: 100%;
    }

    .gallery-img-list li figure img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .coupan-box-detail.d-flex.align-items-center.mb-4 figure img {
        width: 100%;
        height: auto;
    }

    .coupan-box-detail figure {
        height: auto;
    }
}