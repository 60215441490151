body{ background:#F2F4F4; }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.wraper-inner.cpt{background: #FFF;}
.common-wrapper{
  margin: auto;
  max-width: 80%;
  margin-top: 10px;
}
.common-content-card{padding:30px;border: none;}
.option-close{text-decoration:none;padding-left:10px;cursor: pointer;}
.cursor{cursor: pointer;}

button.form-link {
    border: none;
    background: none;
}
.btn-secondry.disabled, .btn-secondry:disabled, fieldset:disabled .btn-secondry{
  opacity: 0.5;
  background: #fa5856;
  cursor:not-allowed !important;
}

.custom-select{
    border-radius: 6px;
    border: 0;
    font-size: 14px;
    color: #868a91;
}
form .is-invalid {
    border : 1px solid #dc3545!important;
}
span.form-error {
    color: #dc3545;
    font-size: 12px;
}
.notifiy-btn.dropdown-toggle::after{content: none;}
.logout-modal{font-size: 2.25rem;color: #fa5856;}
.logout-modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout-modal-button button:first-child{
  margin-right:10px;
}


/*login page support button*/

span.login-support-button {
  position: relative;
  top: 0;
  padding: 8px 23px 8px 23px;
  background: #fa5856;
  border-radius: 7%;
  margin-top: 14px;
  float: right;
  margin-right: 1%;
}
span.login-support-button a{
  text-decoration: none;
  color: white;

}
span.login-support-button:hover {
  opacity: 0.9;
}

/* section.form-page .support-sec.section-space .support-info-main.mt-5 input.form-control ,textarea.form-control.textarea-box{
  background: #e7e7e7;
} */

section.form-page .support-sec.section-space{
  background: white;
}
/*business-configuration-left img*/
.business-configuration-left.d-flex.align-items-center.business-config-img-size figure {
  width: 84px;
  height: 84px;
}
.business-config-img-size figcaption {
  margin-top: 1.7%;
  margin-left: 0.5%;
}
a.btn.btn-secondary.max-w.login-support-back {
  background: #fa5856;
  color: #fff;
  margin-left: 11%;
}
a.btn.btn-secondary.max-w.login-support-back:hover {
  background: #fa5856;
  opacity: .8;
  color: #fff;
}
.bus-conf-content.mb-4.business-address-11 span {
  margin-right: 2%;
}
b.discont-price-add-copan {
  color: red;
  font-weight: 300;
  font-size: 14px;
}

span.form-label-helper {
  color: #8e9198;
  font-weight: 300;
  font-size: 14px;
}

/* Custom radio button style */

input[type="radio"].custom-radio-button {
  accent-color: red;
}

.bus-conf-content p b, .bus-conf-content span b{
  font-weight: 400;
}



.col-md-12.mb-6.public-box-title-section{
  display: inline-block;
  margin-bottom: 23px;
}
.col-md-12.mb-6.public-box-title-section .col-md-2 {
  float: left;
  margin-right: 24px;
  width: 162px;
}

.col-md-12.mb-6.public-box-title-section .col-md-2 img {
  border-radius: 15px;
  width: 162px;
  height: 130px;
}


.col-md-12.mb-6.public-box-title-section .col-md-9.mb-4 {
  float: left;
  margin-top: 36px;
}
.col-md-12.mb-6.public-box-title-section .col-md-9.mb-4 p b {
  margin-right: 8%;
}
.col-md-12.mb-6.public-box-title-section .col-md-9.mb-4 h4 {
  margin-bottom: 6px;
}

.col-md-12.mb-6.public-box-title-section .col-md-9.mb-4 p{
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 530px){
  .col-md-6.mb-3.add-coupan-service-duration {
      width:100%;
  }
  .col-md-12.mb-6.public-box-title-section .col-md-2{
      width: 100%;
      height: auto;
  }
  .col-md-12.mb-6.public-box-title-section .col-md-2 img{
      width: 100%;
      height: auto;
  }
  .col-md-12.mb-6.public-box-title-section .col-md-9.mb-4 p{
      font-size: 14px;
      font-weight: 400;
  }
}


.preload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.8); */
background-color: rgb(255 255 255);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.preloadlogo {
  width: 120px;
  animation: zoomInOut 1.5s infinite alternate; /* Adjust timing as needed */
}

@keyframes zoomInOut {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); } /* Zoom in */
  100% { transform: scale(1); } /* Zoom out */
}


.coupon-tag {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: space-around;
  margin-left: -5px;

}

.coupon-tag li {
  padding: 10px 11px 10px 0px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin: 0 5px;
  font-weight: 600; 
}

.coupon-tag li.active {
  color: red;
  border-bottom-color: red;
  position: relative;
  top: 1px;
  font-weight: 600;
}

.coupan-info-main {
  margin-top: 1.5%;
}
.additional-content {
  margin-top: 1%;
}
.content.coupon-tag-box{
  border-bottom: 1px solid #bec8d9;
}
.coupan-sec-top-head.d-flex.align-items-center.justify-content-between.mb-4 {
  margin-bottom: 1% !important;
}

a.coupan-list-box-url {
  text-decoration: none;
}
a.coupan-list-box-url b.price-text {
  color: black;
}


.login-form.common-form.pb-0.login-form-box-imgremove::after {
  display: none !important;
  background-image: none !important;
  background:none !important;
}
.login-logo-withoutnav {
  text-align: center !important;
}
.login-logo-withoutnav img {
  margin-bottom: 2%;
  width: 163px;
}

span.form-error.form-error-special {
  width: 169px;
  display: -webkit-box;
}
.empty-public-bg {
  text-align: center;
  margin-top: 8%;
  margin-bottom: 8%;
}



.empty-public-bg p {
  font-size: 20px;
  font-weight: 600;
  width: 40%;
  margin-left: 30%;
  margin-top: 1%;
}

input.file-upload-field.disable-field ,.disable-field {
  cursor: not-allowed;
}

a.navbar-brand.desktop-view-logo {
  width: 90px;
}

img.login-head-logo-pg {
  width: 90px;
}

.responsive-nav-acc{
  margin-top: -6px;
}

.coupan-box figure img {
  width: 100%;
  height: 100%;
}
.coupan-temp-box{
  margin-top: 18px;
}
.coupan-content-head.d-flex.align-items-center.justify-content-between.pb-1.null {
  margin-top: 7px;
}

.gallery-img-list li {
  height: auto;
}

.col-md-8.add-new-coupon-box.m-auto .css-b62m3t-container .css-13cymwt-control {
  background: #e6ebeb;
  padding: 3px;
  border: none;
}

.coupan-box-detail.d-flex.align-items-center.mb-4 figure img {
  width: 200px;
  height: auto;
}
label.img-upload-icon.disable-field {
  cursor: not-allowed;
}


.back-shadow-div {
  box-shadow: 62px 2px 1px 79px #ffd6ac !important;
  border-radius: 122px;
  border: 1px solid;
  height: 0px;
  width: 100%;
}

.dashboard-info{
  z-index: 3;
}
.backshadow {
  border-radius: 10px;
  background: #ffd6ac;
  position: relative;
  z-index: 1;
}
.dashboard-info:after{
  display: none;
}

.backshadow:after {
  width: 92%;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  content: "";
  background: #ffd6ac;
  margin: auto;
  bottom: 0px;
  top: 0%;
  z-index: 1;
  border-radius: 10px;
}

.backshadow.green-shadow::after {
  background-color: #018387;
}

.backshadow.pink-shadow::after {
  background-color: #ff9194;
}

.backshadow.blue-shadow::after {
  background-color: #00c9d2;
}

button.add-coupan-btn ,.btn-upload{
  background: #e6ebeb;
}
span.css-1u9des2-indicatorSeparator {
  display: none;
}

.gallery-img-list li figure img {
  width: 100%;
  height: 112px;
  object-fit: cover;
  object-position: top;
}
.coupan-detail-popup p span.right {
  float: right;
}

.coupan-detail-popup p {
  padding-top: 2%;
  padding-bottom: 2%;
  border-bottom: 1px solid gray;
  font-weight: 600;
}
.coupan-detail-popup p.checkbox {
  border: none;
  padding-bottom: 0;
}
.modal-foot.mt-4.coupan-detail-popup {
  text-align: center;
}


.mobilepicker-custom div.MuiInputBase-root{
  padding: 0 !important;
  border: none !important;
  /* width: 174%; */
  margin-top: -6px;
  color: rgb(0 0 0 / 46%);
}
.mobilepicker-custom div.MuiInputBase-root input {
  padding: 10px 0 7px 14px !important;  
  background: #e6ebeb !important;
}

.calendar-custom button.fc-prev-button.fc-button.fc-button-primary ,
.calendar-custom button.fc-next-button.fc-button.fc-button-primary{
  background: #f2f4f4;
  border: none;
  color: #00000026;
}

.calendar-custom .fc .fc-toolbar-title {
  font-size: 21px;
  margin: 0px;
}

.Mui-selected {
  color: black;
  font-weight: bold;
}

/**calendar **/
.calendar-custom button.fc-prev-button.fc-button.fc-button-primary, .calendar-custom button.fc-next-button.fc-button.fc-button-primary {
  background: #f2f4f4;
  border: none;
  box-shadow: none !important;
  color: #00000026;
}
.fc .fc-daygrid-day.fc-day-today{
  background: none !important;
}
.fc-daygrid-event-dot {
  /* display: none; */
  border: calc(var(--fc-daygrid-event-dot-width) / 2) solid #ffa65c;
}
.fc-daygrid-event-harness a {
  color: #ffa65c;
}
.calendar-custom thead {
  background: #f2f4f4;
}
.fc .fc-daygrid-day-number{
  color: #868a91;
  text-decoration: none;
}


.fc-event-selected, .fc-event:focus {
  box-shadow:none !important;
}
.fc-daygrid-dot-event.fc-event-mirror, .fc-daygrid-dot-event:hover {
  background: none !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.fc-event-selected::after, .fc-event:focus::after {
  background: none !important;
}


.calendar-custom table.fc-scrollgrid.fc-scrollgrid-liquid , .calendar-custom table.fc-scrollgrid.fc-scrollgrid-liquid thead{
  border-radius: 10px 13px 0px 0px;
}
.calendar-custom thead a.fc-col-header-cell-cushion ,.calendar-custom thead .fc-daygrid-day-number{
  color: #ff585a;
  text-decoration: none;
}

.editpublic-coupan{
  border: 1px solid black !important;
}
.textarea-box{
  background: #e6ebeb !important;
  color: gray !important
}

.MuiClockPointer-root , .MuiClock-pin.css-umzx0k-MuiClock-pin {
  background-color: #ff0000 !important;
  
}

.MuiClockPointer-thumb.css-f53ilk-MuiClockPointer-thumb , .MuiClockPointer-thumb{
  border: 16px solid #F44336 !important;
  background:none !important;
  width: 0px !important;
  height: 0px !important;
  left: -15px !important;
}

.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: #F44336 !important;
}

.user-profile-drop {
  cursor: pointer;
}
span.notifiy-btn.dropdown-toggle {
  cursor: pointer;
}


.coupon-sec-detail-content.mb-4 {
  display: inline-block;
  margin-right: 14%;
}
.coupon-sec-detail-content.mb-4.coupon-sec-des {
  margin-right: 18%;
}
.lastenddate {
  margin-right: 21.5% !important;
}
.coupon-sec-detail-content.mb-4.coupon-se-gallery {
  display: block;
}

.customdescription-box {
  display: block;
  width: 100%;
}

.disabled-am .MuiTimePickerToolbar-ampmLabel {
  pointer-events: none;
  color: grey; /* Or any other color indicating it's disabled */
}
/* .fade:not(.show) {
  opacity: 1;
  display: block;
} */
.disable-button {
  pointer-events: none;
  opacity: 0.5; /* Optionally reduce opacity to visually indicate it's disabled */
  /* You can add additional styling here to visually indicate the button is disabled */
}
td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink {
  padding: 1.2% 0% 1.2% 0%;
}
td.fc-timegrid-slot.fc-timegrid-slot-lane{
  padding: 0% 0% 0% 0%;
}

.no-event-custom{
  background: none !important;
  border: 0px;
  margin-top: 8px;
  height: 107px;
  margin-bottom: 8px;

}

a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.no-event-custom.fc-timegrid-event.fc-v-event{
  background-color: white;
}
/* .fc-timegrid-event-harness {
  position: relative;
  height: 56px;
} */
.no-event-custom.fc-event-time {
  color: black !important;
}
.no-event-custom.fc-event-title-container{
  color: black !important;
}
:root {
  --fc-today-bg-color: rgba(255, 255, 255, 0.15);
}
h1.model-sm-title.model-custom-title {
  text-align: center;
  font-size: 41px;
  font-weight: 800;
  text-shadow: -2px -2px black;
}

.fc-button.fc-button-primary {
  margin-left: 0%;
  margin-right: 7%;
  background: #f2f4f4;
  border: none;
  box-shadow: none !important;
  color: #00000026;
}
.fc-toolbar-chunk {
  margin-right: 3.5%;
}

a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-timegrid-event.fc-v-event.active-event ,a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event.active-event ,
a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event.active-event,.fc-event.fc-event-start.fc-event-today.fc-event-future.fc-timegrid-event.fc-v-event.active-event{
  height: 52px;
  width: 103%;
  background: white;
  border: none;
  color: black;
  margin-top: 2px;
  padding-left: 10px;
  padding-bottom: 7px;
}
a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-timegrid-event.fc-v-event.active-event.fc-v-event .fc-event-main ,
a.fc-event.fc-event-start.fc-event-end.fc-event-past.fc-timegrid-event.fc-v-event.active-event .fc-event-main ,
a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event.active-event .fc-event-main{
  color: #0c0000;
  height: 100%;
}

a.fc-event.fc-event-start.fc-event-end.fc-event-future.fc-timegrid-event.fc-v-event.active-event {
  height: 52px;
  margin-top: 2px;
}

/* a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-timegrid-event.fc-v-event.active-event {
  position: relative;
  width: 100%;
  z-index: 0;
  height: 52px;
}


a.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.no-event-custom.fc-timegrid-event.fc-v-event .fc-v-event .fc-event-main{
  color:black;
} */

.fc-toolbar-chunk {
  margin-right: 0;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  margin-right: 0px;
}

.calendar-custom table.fc-scrollgrid.fc-scrollgrid-liquid, .calendar-custom table.fc-scrollgrid.fc-scrollgrid-liquid thead {
  border-radius: 0px 0px 0px 0px !important;
}
.custom-calender-view-header{
  display: table;
  width: 100%;
  background: #f2f4f4;
  border-radius: 10px 10px 0px 0px;
  padding-bottom: 6px;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr.custom-toolbar-box{
  margin-bottom: 30px !important;
}
.fc .fc-toolbar.fc-header-toolbar{
  margin-bottom: 0px !important;
}
.custom-calender-view-header ul{
  margin-left:0px;
  display: flex;
  flex-wrap: wrap;
}
.custom-calender-view-header ul li {
  list-style: disc;
  margin-left: 40px;
}
.custom-calender-view-header ul li span {
position: relative;
left: -9px;
top: -4px;
}
.custom-calender-view-header ul li.orange::marker {
  size: 15px;
  color: #ffa65c;
  font-size: 29px;
}
.custom-calender-view-header ul li.green::marker {
  size: 15px;
  color: #01cf6c;
  font-size: 29px;
}
.custom-calender-view-header ul li.red::marker {
  size: 15px;
  color: #ff585a;
  font-size: 29px;
}
.custom-calender-view-header ul li.darkgreen::marker {
  size: 15px;
  color: #067c45;
  font-size: 29px;
}


button.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  background: #ff585a;
  font-weight: 800;
}


button.fc-dayGridMonth-button.fc-button.fc-button-primary:hover {
  background-color: #ff585e;
  border-color: var(--fc-button-hover-border-color);
  color: var(--fc-button-text-color);
}

button.fc-dayGridMonth-button.fc-button.fc-button-primary.spaceclanderview {
  margin-right: 19px;
  border-radius: 0px 2px 3px 0px;
}



/**/
.react-time-picker{
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);    
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: .375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-time-picker__wrapper{
 border: unset !important;
}

 
.react-time-picker__clock.react-time-picker__clock--open{
    display: none !important;
}
.react-time-picker__inputGroup__input.react-time-picker__inputGroup__amPm{
    width: 50px !important;
}


.green-clender-dash-info{
  background-color: #01cf6c;
}

.green-clender-dash-info::after {
  background-color: #01cf6c;
}

.coupan-calender-spacing{
  margin-top: 25px;
}


.green-light-dash-info {
  background-color: #067c45;
}

.green-light-dash-info-shadow::after {
  background-color: #067c45;
}

.fc-event-time {
  display: none;
}

.fc-timegrid-event-harness {
  position: relative !important;
}

.past-time-slot{
  background-color: #868a9148;
}



.purchased-event.fc-event-title.fc-sticky{
  color: red;
  font-weight: 800;
  font-size: 14px;
  padding-top: 12px;
}

.redeemed-event .fc-event-title.fc-sticky ,
.redeemed-event .fc-event-title{
  color: #007c48;
  font-weight: 800;
  font-size: 14px;
  padding-top: 5px;
}

.active-event .fc-event-title.fc-sticky {
  color: #ffa664 !important;
  font-weight: 800;
  font-size: 14px;
  padding-top: 12px;
}

.purchased-event .fc-event-title-container , .purchased-event .fc-event-title{
  color: #00cf71 !important;
  font-weight: 800;
  font-size: 14px;
  padding-top: 5px;
}

.expired-event .fc-event-title-container , .expired-event .fc-event-title{
  color: rgb(255 88 94) !important;
  font-weight: 800;
  font-size: 14px;
  padding-top: 5px;
}

.fc-event-title.fc-sticky:before {
  content: "• ";
  margin-right: -2px;
}

.past-event-custom {
  opacity: 0.5; 
  background-color: #efefef;
}

a.fc-event.fc-event-start {
  cursor: pointer;
}

.calenderedit-coupan-temp{
  width: 44%;
}


.redeemed-event .fc-event-title {
  color: #007c48;
  font-weight: 800;
  font-size: 16px;
  padding-top: 5px;
}

.fc-daygrid-event-dot {
  display: none;
}
.fc-event-title:before {
  content: "• ";
  margin-right: -2px;
}
.past-cell {
  background-color: #ccc;
}

td.fc-day.fc-day-past.fc-daygrid-day ,.fc-timegrid-axis-frame.fc-scrollgrid-shrink-frame.fc-timegrid-axis-frame-liquid {
  background-color: #8080801a;
}

.MuiFormControl-root.MuiTextField-root.mobilepicker-custom.css-z3c6am-MuiFormControl-root-MuiTextField-root{
  width: 100% !important;
}

.redeemcoupan-box p {
  font-weight: 600;
  margin-bottom: 8px;
}

.redeem-coupan-id-field {
  padding: 10px 0 10px 14px !important;
  background: #f2f4f4 !important;
  border: none;
  border-radius: 5px;
  width: 100%;
}

.redeem-coupan-id-field:focus-visible {
  outline: none;
}


.MuiFormControl-root.MuiTextField-root.mobilepicker-custom.timepicker-custom {
  min-width: 100% !important;
}

.timepicker-custom.mobilepicker-custom div.MuiInputBase-root {
  padding: 0% 7% 0% 0% !important;
  border: none !important;
  width: 100% !important;
  margin-top: -6px;
  color: rgb(0 0 0 / 46%);
  background: #e6ebeb !important;
}
.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.avatar-preview.businessdetails-custom{
  width: 100%;
  height: 79px;
}


.active-event{
  padding-top: 0px !important;
  height: 43px !important;
  margin-top: 5px !important;
  background: white;
  border: none;
  width: fit-content !important;
  padding-top: 0px !important;
}

.purchased-event{
  padding-top: 0px !important;
  height: 43px !important;
  margin-top: 6px !important;
  background: white;
  border: none;
  width: fit-content !important;
}
.redeemed-event{
  padding-top: 0px !important;
  height: 43px !important;
  margin-top: 11px !important;
  background: white;
  border: none;
  width: fit-content !important;
}
.expired-event{
  padding-top: 0px !important;
  height: 43px !important;
  margin-top: 11px !important;
  background: white;
  border: none;
  width: fit-content !important;
}

.caleender-past-dates {
  background: #edecec8a;
}

.event-value span {
  cursor: pointer;
}


/*Calender Loader*/
@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.skeleton-loader {
  width: 100%;
  margin: 0px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.skeleton-header,
.skeleton-content {
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
  margin-bottom: 10px;
}

.skeleton-header {
  width: 50%;
}

.skeleton-content {
  width: 100%;
}

.mode-cursion-calender{
  cursor: pointer;
}

p.show-more-events {
  cursor: pointer;
}
span.show-more-events:before {
  display: none;
}

.calender-events-details.box-shadow.coupan-box.Popular-coupan-box.p-3.d-flex {
  padding-left: 16px !important;
  cursor: pointer;
}


.MuiStack-root {
  width: 100% !important;
  flex-direction: column !important;
}


td.table-left-box{
  vertical-align: middle !important;
}

.skeleton-loader.orange{
  background-color: #ffa65c;
}
.skeleton-header.orange {
  background: linear-gradient(90deg, #f0f0f0, #ffa65c, #f0f0f0);
  animation: shimmer 1.5s infinite;
}


@keyframes shimmer1 {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

button.reset-button-style {
  width: auto;
  padding: 0.5% 2% 0.5% 2%;
  border: navajowhite;
  float: right;
  margin-top: 3px;
  border-radius: 5px;
}

.form-group.mb-2.addressline2 {
  margin-top: 31px;
}

.coupon-sec-detail-content.mb-4.coupon-sec-des.customdescription-box {
  margin: 0;
}


button.MuiButtonBase-root.Mui-disabled.active {
  background: red;
  color: white;
}

p.title-text-calender-model {
  padding-left: 24%;
  text-align: end;
}

.calender-box.mt-4{
padding-bottom: 80px;
}



/************************************/
.custom-payment {
  height: auto !important;
  border: 1px solid #d8e2e9;
  font-size: 15px;
  color: #000;
  border-radius: 4px;
  padding: 13px 16px;
  background: #e6ebeb !important;
}

input.form-control {
    background: #e6ebeb;
    border-radius: 6px;
    border: 0;
    font-size: 14px;
    color: #868a91;
    font-weight: 600;
}

input.custom-pay-input:focus {
  background: #e6ebeb;
}



/* Loader styles */
#loaderImage {
  display: block;
  position: relative;
  left: 0%;
  top: 53%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #ff585a;
  width: 70px;
  margin-top: 17px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

ul.suggestion-box {
  border: 1px solid #e6ebeb;
  position: relative;
  margin-top: -4px;
  border-top: none;
  z-index: 11;
  padding: 0;
}

li.suggestion-box-list {
  padding: 6px 0px 1px 14px;
  cursor: pointer;
  background-color: white;
}

li.suggestion-box-list:hover {
  background: #e6ebeb;
}

span.apexcharts-legend-text {
  color: black !important;
}


.business-configuration-left.d-flex.align-items-center.business-config-img-size figure img {
  height: inherit;
  width: 100%;
}

.account-type-field{
  margin-bottom: 13px;
}



.fc-direction-ltr {
  direction: ltr;
  text-align: left;
}
.fc {
  display: flex;
  flex-direction: column;
  font-size: 1em;
}
.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr.custom-toolbar-box {
  margin-bottom: 30px !important;
}
.fc .fc-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.fc {
  font-size: 1em;
}
.calendar-custom button.fc-prev-button.fc-button.fc-button-primary, .calendar-custom button.fc-next-button.fc-button.fc-button-primary {
  background: #f2f4f4;
  border: none;
  box-shadow: none !important;
  color: #00000026;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.fc .fc-button:not(:disabled) {
  cursor: pointer;
}


.fc-prev-button .fc-icon-chevron-left{background: url(../images/arrow-left-s-line.svg) no-repeat center center; display: block; width: 30px; height: 30px; margin: 0 !important;}
.fc-next-button .fc-icon-chevron-right{background: url(../images/arrow-right-s-line.svg) no-repeat center center; display: block; width: 30px; height: 30px; margin: 0 !important;}

.incomplete-detail-message{
  width: 100%;
  background-color: red;
  border-radius: 5px;
  padding: 4px 0px 4px 9px;
  color: white;
  font-weight: 800;
}

.incomplete-detail-message a{
  color:wheat;
}

.event-value samp {
  cursor: pointer;
}


select.form-control,.custom-select ,.file-upload-wrapper:after{
  background-color: #e6ebeb;
}


.form-control:disabled {
  background-color: #e6ebeb;
  opacity: 1;
}

select.form-select{
  background-color: #e6ebeb;
}


.customselect-div-css > div:nth-of-type(1) {
  background-color: #e6ebeb; 
}

.public-button-back {
  margin-left: 6%;
  /* width: 100% !important; */
}

.logout-payment-button span{
  color:white;
  cursor: pointer;
}

.dashboard-info-main a {
  text-decoration: none ;
} 

.green-clender-dash-info .dashboard-info-right span {
  color: #00cf71;
}

.green-light-dash-info .dashboard-info-right span {
  color: #067c45;
}